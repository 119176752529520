import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isDataReady: false,
        otherLocalesUrls: {},
        seo: {
            title: null,
            titleRaw: {},
            description: null,
            descriptionRaw: '',
            keywords: [],
            score: '',
            social: {
                twitter: {
                    handle: '',
                    title: '',
                    imageId: '',
                    description: '',
                },
                facebook: {
                    handle: '',
                    title: '',
                    imageId: '',
                    description: '',
                },
            },
            advanced: {
                robots: [],
                canonical: '',
            },
        },
    },
    mutations: {
        SET_DATA_READY (state, status) {
            state.isDataReady = status;
        },
        SET_OTHER_LOCALES_URLS (state, urls) {
            state.otherLocalesUrls = urls;
        },
        SET_SEO (state, seo) {
            state.seo = { ...seo, description: seo.descriptionRaw['1'], title: seo.titleRaw['1'] }
        },
    },
    actions: {
    },
    getters: {
        isDataReady: (state) => state.isDataReady,
        otherLocalesUrls: (state) => state.otherLocalesUrls,
        seo: state => state.seo,
    },
});
