import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store';
import i18n from '@/i18n';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/projets',
        component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/projets/:slug',
        component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
        meta: { lang: 'fr', noFooter: true },
    },
    {
        path: '/approche',
        component: () => import(/* webpackChunkName: "approach" */ '../views/Approach.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/atelier',
        component: () => import(/* webpackChunkName: "workshop" */ '../views/Workshop.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
        meta: { lang: 'fr' },
    },

    // English
    {
        path: '/en/',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
        meta: { lang: 'en' },
    },
    {
        path: '/en/projects',
        component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
        meta: { lang: 'en' },
    },
    {
        path: '/en/projects/:slug',
        component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
        meta: { lang: 'en', noFooter: true },
    },
    {
        path: '/en/approach',
        component: () => import(/* webpackChunkName: "approach" */ '../views/Approach.vue'),
        meta: { lang: 'en' },
    },
    {
        path: '/en/workshop',
        component: () => import(/* webpackChunkName: "workshop" */ '../views/Workshop.vue'),
        meta: { lang: 'en' },
    },
    {
        path: '/en/contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
        meta: { lang: 'en' },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 })
            }, 200)
        })
    }
});


router.beforeEach(async (to, from, next) => {
    Store.commit('SET_DATA_READY', false);

    setTimeout(() => {
        const { lang } = to.meta;
        document.documentElement.lang = lang;
        i18n.locale = lang;
        next();
    }, 250); // 250 is time of page loader fade-in
})


export default router;
