<template>
    <div id="app">
        <Header />

        <router-view :key="$route.fullPath" />

        <Footer v-if="hasFooter"/>

        <transition name="quick-fade">
            <Loader v-if="!isDataReady" />
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';
import Loader from '@/components/Loader';

export default {
    name: 'App',

    components: {
        Header,
        Footer,
        Loader
    },

    computed: {
        ...mapGetters(['seo']),

        isDataReady() {
            return this.$store.state.isDataReady;
        },

        hasFooter() {
            return !this.$route.meta.noFooter;
        },
    },

    created() {
        gsap.registerPlugin(ScrollTrigger);
    },

    metaInfo() {
        return {
            ...(this.seo.title && { title: this.seo.title }),
            ...(this.seo.title && { titleTemplate: '%s | Élément Bois' }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

</style>
