<template>
    <footer>
        <div class="links">
            <div class="item">{{ $t('footer.copyright') }}</div>
        </div>
        <div class="leeroy">
            <a href="https://leeroy.ca/" target="_blank" rel="noopener">LEEROY</a>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
}
</script>
