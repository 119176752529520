<template>
    <header :class="{ 'header--fullscreen': fullscreenMenuToggled }">
        <div class="container-header">
            <div class="burger" @click="toggleFullscreenMenu()">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="brand">
                <router-link :to="$t('nav.home.uri')" :title="$t('nav.home.title')">
                    <img src="@/assets/images/svg/logo.svg" alt="">
                </router-link>
            </div>
            <div class="language-switcher" @click="changeLocale(otherLocale)">
                {{ otherLocale }}.
            </div>
        </div>
        <transition name="fade">
            <div v-if="fullscreenMenuToggled" class="nav">
                <span class="nav__close" @click="closeFullscreenMenu()">
                    <img src="@/assets/images/svg/x.svg" :alt="$t('nav.close')">
                </span>
                <ul class="main-nav">
                    <li class="nav__item">
                        <router-link :to="$t('nav.projects.uri')" :title="$t('nav.projects.title')" class="nav__link" @click.native="closeFullscreenMenu()"><span class="nav__link__text">{{ $t('nav.projects.title') }}</span></router-link>
                        <div class="nav__item__hoverimg"><img src="https://elementbois.s3.us-east-2.amazonaws.com/burger-hover-projects.jpg" alt="" /></div>
                    </li>
                    <li class="nav__item">
                        <router-link :to="$t('nav.approach.uri')" :title="$t('nav.approach.title')" class="nav__link" @click.native="closeFullscreenMenu()"><span class="nav__link__text">{{ $t('nav.approach.title') }}</span></router-link>
                        <div class="nav__item__hoverimg"><img src="https://elementbois.s3.us-east-2.amazonaws.com/burger-hover-approach.jpg" alt="" /></div>
                    </li>
                    <li class="nav__item">
                        <router-link :to="$t('nav.workshop.uri')" :title="$t('nav.workshop.title')" class="nav__link" @click.native="closeFullscreenMenu()"><span class="nav__link__text">{{ $t('nav.workshop.title') }}</span></router-link>
                        <div class="nav__item__hoverimg"><img src="https://elementbois.s3.us-east-2.amazonaws.com/burger-hover-workshop.jpg" alt="" /></div>
                    </li>
                    <li class="nav__item">
                        <router-link :to="$t('nav.contact.uri')" :title="$t('nav.contact.title')" class="nav__link" @click.native="closeFullscreenMenu()"><span class="nav__link__text">{{ $t('nav.contact.title') }}</span></router-link>
                        <div class="nav__item__hoverimg"><img src="https://elementbois.s3.us-east-2.amazonaws.com/burger-hover-contact.jpg" alt="" /></div>
                    </li>
                </ul>
            </div>
        </transition>
    </header>
</template>

<script>
import { gsap } from 'gsap';

export default {
    name: 'Header',

    data() {
        return {
            fullscreenMenuToggled: false,
        };
    },

    computed: {
        otherLocale() {
            return this.$i18n.locale == 'fr' ? 'en' : 'fr';
        },

        otherLocalesUrls() {
            return this.$store.state.otherLocalesUrls;
        }
    },

    mounted() {
        const menuAnim = gsap.to('.brand', {
            scale: 0.8,
            ease: 'power3.inOut',
            paused: true,
        });
        window.addEventListener('scroll', (e) => scrollY > 1 ? menuAnim.play() : menuAnim.reverse());
    },

    methods: {
        toggleFullscreenMenu() {
            this.fullscreenMenuToggled = !this.fullscreenMenuToggled;
        },

        closeFullscreenMenu() {
            this.fullscreenMenuToggled = false;
        },

        changeLocale(newLocale) {
            this.fullscreenMenuToggled = false;

            const url = this.otherLocalesUrls[newLocale];
            window.location.href = url || '/';
        },
    },
};
</script>
