/**
 * CraftApi plugin
 * Please use in coordination with the Element API plugin
 * https://github.com/craftcms/element-api
 */

import axios from 'axios';
import i18n from '../i18n';

const ELEMENT_TYPE = 'pages';

class CraftApi {
    constructor() {
        if (!process.env.VUE_APP_SITE_BASE_URL) {
            // eslint-disable-next-line no-console
            console.error('Error: Please set VUE_APP_SITE_BASE_URL in .env');
        }

        this.baseUrl = '/api';
    }

    /**
     *
     * Gets any entry by slug, and defaults to pages
     *
     * @param slug
     * @param type
     *
     * @return object
     *
     */
    async getContentBySlug(slug, type = ELEMENT_TYPE) {
        let response;

        const locale = i18n.locale === 'fr' ? '' : '/' + i18n.locale;

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl}/${type}/${slug}.json`)
            .then((res) => {
                response = res;
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                };
            });

        return response;
    }

    /**
     *
     * Gets everything for debug purposes
     * Enabled only in development
     *
     * @return object
     *
     */
    async getAllEntries() {
        let response;

        if (process.env.NODE_ENV === 'production') {
            return {
                code: 403,
                message: 'Forbidden',
            };
        }

        const locale = i18n.locale === 'fr' ? '' : '/' + i18n.locale;

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + locale + this.baseUrl}/debug.json`)
            .then((res) => {
                response = res;
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                };
            });

        return response;
    }
}

export default {
    install(Vue) {
        Vue.prototype.$craft = new CraftApi();
    },
};
